import React, { useState } from 'react';
import './Header.css';
import glacefull2png from "../../assets/glacefull2png.png";
import Glace_Menu_Update_6 from "../../assets/Glace_Menu_Update_6.png";
import ToastImage from "../../assets/ToastImage.png";
// import UberEatslogo from "../../assets/UberEatslogo.png";
import { useNavigate } from 'react-router-dom';

const Header = () => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);

  const handleMenuClick = () => {
    navigate('/menu', { state: { menuImage: Glace_Menu_Update_6 } });
  };

  const handleEventsClick = () => {
    window.location.href = 'https://docs.google.com/forms/d/e/1FAIpQLSd4GMBEcXEozMoXwcYtOuX9_xktEoAnmDGLG937rFIi8EoaSg/viewform';
  };

  const handleToastClick = () => {
    window.location.href = 'https://docs.google.com/forms/d/e/1FAIpQLSfsLtvy5Khc_5FeHLrpnjgRqw7SiKw0QL_Jmw2NLQ_ELlUzvA/viewform';
  };

  const handlePressInquiriesClick = () => {
    window.location.href = 'mailto:glace@monacreative.com';
  }

  return (
    <div className="app__container">
      <div className="app__header-top">
        <div className="centered-buttons">
          <div className="app__header-left">
            <button className="action__button nav-link" onClick={handleMenuClick}>
              Menu
            </button>
          </div>
          <div className="app__header-center">
            <button className="action__button nav-link" onClick={handlePressInquiriesClick}>
              Press Inquiries
            </button>
          </div>
          <div className="app__header-right">
            <button className="action__button nav-link" onClick={handleToastClick}>
              Order Cakes
            </button>
            {/* Events Button */}
            <button className="action__button nav-link" onClick={handleEventsClick}>
              Events
            </button>
          </div>
        </div>
      </div>

      <header className="app__header"></header>
      <div className="app__header app__wrapper section__padding" id="home">
        <img src={glacefull2png} alt="Header" />
      </div>
      <div className="app__footer">
        <a
          href="https://maps.google.com/maps?q=1266+Madison+Avenue,+10128"
          target="_blank"
          rel="noopener noreferrer"
          className="nav-link"
        >
          <span>1266 Madison Avenue</span>
          <p></p>
          <span>Between 90th and 91st</span>
        </a>
        <div className="contact-info">
          <a href="tel:347-502-6445" className="nav-link">
            <span>(347) 502-6445</span>
          </a>
          <p></p>
          <span> 12PM-8PM Everyday</span>
        </div>
      </div>


      {showModal && (
        <div className="order-modal">
          <button className="order-modal-close" onClick={() => setShowModal(false)}>X</button>
          <div className="order-modal-content">
            <button className="order-modal-img" onClick={handleToastClick}>
              <img src={ToastImage} alt="Order via Toast" />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Header;
