
import React from 'react';
import Glace_Menu_Update_6 from "../../assets/Glace_Menu_Update_6.png";
import '../MenuPage/MenuPage.css';
// import { Link } from 'react-router-dom';

const MenuPage = () => {
  return (
    <div className="menu-page-container">
      <div className="menupage">
        {/* <h2>Order Online</h2> */}
      </div>
      <img src={Glace_Menu_Update_6} alt="Menu" />
    </div>
  );
};

export default MenuPage;
