import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Header from '../src/container/Header/Header';
import MenuPage from './container/MenuPage/MenuPage';

const App = () => {
  return (
    <div>
      {/* Your other components and layout here */}
      <Routes>
        <Route path="/" element={<Header />} />
        <Route path="/menu" element={<MenuPage />} />
        {/* Add more routes as needed */}
      </Routes>
    </div>
  );
};

export default App;
